import React, { useState, useEffect } from "react"

import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Contributors from "../../../components/athena/contributors"
import Layout, { athenaTheme } from "../../../components/layout"
import PreFooter from "../../../components/athena/preFooter"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import CircleVideoMorph from "./article-02/circleVideo"
import MoreArticles from "../../../components/athena/moreArticlesJoy"

import {
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookShareButton,
  FacebookIcon,
} from "react-share"

import footerImg from "../../../images/athena-04-resilience/02-engestation/footer-image.png"
import styles from "./engestation.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
  headerPosition: styles.position,
}

const Article = ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  //const coverImages = article.coverImages ? article.coverImages[0] : null

  let [fadeIn, setfadeIn] = useState(true)
  useEffect(() => {
    setfadeIn(1)
    let timeoutId = setTimeout(() => {
      setfadeIn(2)
      timeoutId = setTimeout(() => {
        setfadeIn(3)
        timeoutId = setTimeout(() => {
          setfadeIn(4)
          timeoutId = setTimeout(() => {
            setfadeIn(5)
            timeoutId = setTimeout(() => {
              setfadeIn(6)
            }, 600)
          }, 350)
        }, 300)
      }, 200)
    }, 200)
    return () => clearTimeout(timeoutId)
  }, [])

  let group = index => ({
    style: {
      transition: "opacity .6s ease-in-out",
      opacity: fadeIn >= index ? 1 : 0.0125,
    },
  })
  return (
    <Layout seo={seo} mode="athena" theme={theme} {...group(1)}>
      <Helmet>
        <body className={`athena ${styles.body}`} />
      </Helmet>
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.hero}>
            <div className={styles.heroOverlay} {...group(6)}></div>
            <div className={styles.heroLeftVideo} {...group(5)}>
              <video autoPlay loop muted playsInline>
                <source
                  src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/left-hero-hvec.mov"
                  type='video/mp4; codecs="hvc1"'
                />
                <source
                  src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/left-hero.webm"
                  type="video/webm"
                />
              </video>
            </div>

            <div className={styles.issue} {...group(3)}>
              <p className="a-h-5">
                Issue 04 / Exploring Joy, Reimagining Resilience
              </p>
            </div>

            <div className={styles.title} {...group(5)}>
              <div className="columns is-centered no-vertical-gap">
                <div className="column is-9">
                  <h1 className="a-h-display">{article.title}</h1>
                </div>
              </div>
            </div>

            <div className={styles.heroRightVideo} {...group(5)}>
              <video autoPlay loop muted playsInline>
                <source
                  src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/right-hero-hvec.mov"
                  type='video/mp4; codecs="hvc1"'
                />
                <source
                  src="https://zj-v6.imgix.net/athena-content/I04-Exploring-Joy/I04-A02-Engestation/right-hero.webm"
                  type="video/webm"
                />
              </video>
            </div>

            <div className={styles.subtitle} {...group(5)}>
              <p className="a-h-5">An essay by Jordana Rothman</p>
            </div>
          </div>

          <div className={`${styles.articleBody}`}>
            <div className="wrapper">
              <div className={styles.readingTime}>
                <p className={styles.reading}>12 min reading time</p>
                <p className={styles.readingMobile}>12 min read</p>
                <div className={styles.socialContainer}>
                  <p className={styles.share}>Share —</p>
                  <TwitterShareButton
                    url={"https://zeusjones.com/athena/articles/engestation/"}
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <TwitterIcon />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={"https://zeusjones.com/athena/articles/engestation/"}
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <LinkedinIcon />
                  </LinkedinShareButton>

                  <FacebookShareButton
                    url={"https://zeusjones.com/athena/articles/engestation/"}
                    title={article.title}
                    className={styles.shareButtons}
                    via="zeusjones"
                  >
                    <FacebookIcon />
                  </FacebookShareButton>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-9-desktop">
                  <div className={`${styles.articleIntro} user-content-athena`}>
                    <p>
                      Resilience is often quiet. It can be seen as a kind of
                      meditation, a sustained focus—the decision to allow
                      yourself to sit comfortably in an in-between place.
                      Resilience is finding the joy, the pleasure—the meaning
                      held inside of everything—despite the swirling clouds
                      outside. Jordana Rothman is not just a creative at Zeus
                      Jones, she's also a former editor for Food &amp; Wine
                      magazine and a recent mother. In 2020, as Jordana and her
                      wife Blake waited for the arrival of their daughter Roux
                      in the midst of a pandemic, she found herself focusing on
                      what she does naturally: cooking. And from that was
                      birthed this meditation on what resilience looks like at
                      home when the world is in an endless state of feud and
                      flux.
                    </p>
                    <p>
                      {" "}
                      <a href="https://zeusjones.com/people/jason-zabel">
                        &ndash; Jason Zabel
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-9-desktop">
                  <div className="user-content-athena">
                    <p>
                      <span className={styles.drop}>
                        <i>I</i>t c<em>o</em>uld b<b>e</b> old la<b>c</b>e o
                        <b>r</b> ho<b>n</b>e<b>y</b>co<b>m</b>b
                      </span>{" "}
                      or the web of a spider, this sheet of dough, folded over
                      and over and into itself—its layers like pages, a book of
                      fat and flour. But really it is more butter than anything
                      else, which is why you must work quickly, before the heat
                      of your fingertips melts it. And so you are swift with
                      your knife, one hundred or more tiny cuts in this sheet of
                      puff pastry which you will use to wrap a grand savory pie
                      the Germans called kohlgeback, the Russians called
                      kiulibiak and the French, in their way, called coulibiac
                      for the rest of time. You hold it up by its edges, so the
                      weight of the dough stretches those knife slits into wide
                      open eyes, until it is near-translucent, and so near to
                      tearing.
                    </p>
                    <p>
                      And there, just there is when you have to act, not just
                      with quickness now but with grace too, because the stakes
                      are quite high and really you've come this far. You lay
                      down the veil in one smooth movement, you tuck in its
                      latticed edges—quickly, again, quickly. You brush it with
                      beaten egg so that it will turn golden and lacquered as it
                      bakes, and you crush crystals of salt over the top, and if
                      you were the type to genuflect you might do that. But you
                      are not, and so you take in this thing you have made in
                      its all paleness, in all its before-ness a final time and
                      you slide it into the hot oven, and you hope for the best.
                    </p>

                    <div
                      className={`${styles.circleParagraph} ${styles.circleParagraphOne}`}
                    >
                      <div className={styles.left}>
                        <p>
                          This black-box cooking, this category of food that
                          took shape in the Middle Ages as “engastration” but
                          which I call “things-in-things,” took hold of me (in
                          an almost unbearably literal way), while my wife was
                          pregnant. On that matter, there is so little levity in
                          the particulars of two queer people making a baby. The
                          novelty of swiping through donor profiles like sperm
                          Tinder wears off after the first $1000 vial doesn't
                          bear fruit. All the language of follicles and
                          motility; all the pills that turn eggs
                          into&nbsp;pulsing
                        </p>
                      </div>
                      <div className={styles.right}>
                        <p>
                          neon INQUIRE WITHINS such that the dopey man-milt
                          might have a shot at slouching towards Bethlehem. The
                          drug cocktails that I, a person who often forgets to
                          drink water before 3pm, was trusted to mix and inject
                          into Blake's lovely flesh. That year of pain and loss
                          and the slow trickle of blood that announced the awful
                          news every month—until she was, at last, pregnant. A
                          thing the size of a poppy seed, inside a thing the
                          shape of my wife. You might genuflect. You might hope
                          for the&nbsp;best.
                        </p>
                      </div>
                      <CircleVideoMorph />
                    </div>
                    <div
                      className={`${styles.circleParagraphMobile} ${styles.circleParagraphOneMobile}`}
                    >
                      <p>
                        This black-box cooking, this category of food that took
                        shape in the Middle Ages as “engastration” but which I
                        call “things-in-things,” took hold of me (in an almost
                        unbearably literal way), while my wife was pregnant. On
                        that matter, there is so little levity in the
                        particulars of two queer people making a baby. The
                        novelty of swiping through donor profiles like sperm
                        Tinder wears off after the first $1000 vial doesn't bear
                        fruit. All the language of follicles and motility; all
                        the pills that turn eggs into pulsing neon INQUIRE
                        WITHINS such that the dopey man-milt might have a shot
                        at slouching towards Bethlehem. The drug cocktails that
                        I, a person who often forgets to drink water before 3pm,
                        was trusted to mix and inject into Blake's lovely flesh.
                        That year of pain and loss and the slow trickle of blood
                        that announced the awful news every month—until she was,
                        at last, pregnant. A thing the size of a poppy seed,
                        inside a thing the shape of my wife. You might
                        genuflect. You might hope for the best.
                      </p>
                      <CircleVideoMorph />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-12-tablet is-12-desktop">
                  <h2 className={styles.h2}>
                    B<b>U</b>t <b>T</b>hen, <b>t</b>h<b>i</b>s wa<b>s</b> J
                    <b>a</b>nua<b>r</b>y <em>o</em>f <b>2</b>0<b>2</b>0. D
                    <em>o</em>
                    <b>n't</b> ma<b>k</b>e <b>m</b>e s<b>a</b>y i<b>t</b>.
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-9-desktop">
                  <div className="user-content-athena">
                    <p>
                      We were all inside back then. Blake's body grew and we
                      found ways to soften her skin, to make room. Shea butter
                      smoothed into the taut bits, hips and limbs squeezed and
                      tugged. In the kitchen I saw how corn husks would crack
                      when dry, learned to soak them in hot water to make them
                      pliable, folded them around shredded pork and masa for
                      tamales. I'd stand them up in a tall pot and steam them
                      until the dough, stained rust with guajillo chiles, would
                      tell me by scent that it was done.
                    </p>

                    <div
                      className={`${styles.circleParagraph} ${styles.circleParagraphTwo}`}
                    >
                      <div className={styles.left}>
                        <p>
                          This was before vaccines (however briefly) made things
                          feel safe again (however nominally). This was when we
                          were still soaping down bags of rice and spraying
                          Lysol on cans of seltzer and leaving packages
                          untouched for a week before opening them with gloved
                          hands. There were appointments we were both advised to
                          skip, and others I wasn't permitted to come to (“out
                          of an abundance of caution” can join “unprecedented
                          times” in the linguistic ash heap). Blake assured me
                          the heartbeat
                        </p>
                      </div>
                      <div className={styles.right}>
                        <p>
                          was in there, that she'd heard it with the midwife,
                          that it had been quick and strong. I pressed my ear
                          against her belly; I tried a shitty doppler; I willed
                          it to thump. In the kitchen I mixed pork and ginger
                          and tender herbs and Szechuan peppercorns, and I
                          pressed teaspoons of the filling into floury wrappers.
                          I ran a fingertip through water and pleated the
                          dumpling skins, lining them up on parchment paper,
                          tidy little parcels to be crisped in a pan. The
                          interiority of some things was unknowable then. But we
                          did have dumplings.
                        </p>
                      </div>
                      <CircleVideoMorph />
                    </div>
                    <div
                      className={`${styles.circleParagraphMobile} ${styles.circleParagraphTwoMobile}`}
                    >
                      <p>
                        This was before vaccines (however briefly) made things
                        feel safe again (however nominally). This was when we
                        were still soaping down bags of rice and spraying Lysol
                        on cans of seltzer and leaving packages untouched for a
                        week before opening them with gloved hands. There were
                        appointments we were both advised to skip, and others I
                        wasn't permitted to come to (“out of an abundance of
                        caution” can join “unprecedented times” in the
                        linguistic ash heap). Blake assured me the heartbeat was
                        in there, that she'd heard it with the midwife, that it
                        had been quick and strong. I pressed my ear against her
                        belly; I tried a shitty doppler; I willed it to thump.
                        In the kitchen I mixed pork and ginger and tender herbs
                        and Szechuan peppercorns, and I pressed teaspoons of the
                        filling into floury wrappers. I ran a fingertip through
                        water and pleated the dumpling skins, lining them up on
                        parchment paper, tidy little parcels to be crisped in a
                        pan. The interiority of some things was unknowable then.
                        But we did have dumplings.
                      </p>
                      <CircleVideoMorph />
                    </div>

                    <p>
                      A literalist would tell you these foods don't really
                      qualify as engastration. Strictly speaking, engastration
                      is the communion of two or more species (TW for the
                      squeamish and/or the abstinent), one stuffed inside
                      another like a matryoshka doll or else grafted together in
                      the manner of P.T. Barnum's half-monkey, half-fish Fiji
                      mermaid—but rather than turn it out on the sideshow
                      circuit, the idea instead is to eat it.
                    </p>

                    <div
                      className={`${styles.circleParagraph} ${styles.circleParagraphThree}`}
                    >
                      <div className={styles.left}>
                        <p>
                          Make no mistake though, the history of engastration is
                          as much about spectacle as any of Barnum's curios. It
                          is said that the cockentrice was first prepared for
                          the delight of King Henry VII, a roast that saw the
                          front half of a suckling pig stitched to the bottom
                          half of a capon, brushed with egg and saffron and gold
                          leaf. The Tudors ate Christmas Pie—turkey stuffed with
                          goose stuffed with chicken stuffed with partridge
                          stuffed with pigeon. Not to be outdone by the English,{" "}
                        </p>
                      </div>
                      <div className={styles.right}>
                        <p>
                          the 19th-century French rôti sans pareil—roast without
                          equal—contained 17 birds nested within one another. In
                          India, the Mughlai dish parinde-me-parinda packed an
                          egg into a quail into a chicken into a duck. In
                          Greenland some Inuit people celebrate weddings with
                          kiviak, hundreds of tiny auk (a type of sea bird)
                          fermented inside the belly of a seal. Closer to home
                          we have the turducken, our very own frankenfeat, and
                          its sweeter cousins, the piecakens and cherpumples.
                        </p>
                      </div>
                      <CircleVideoMorph />
                    </div>
                    <div
                      className={`${styles.circleParagraphMobile} ${styles.circleParagraphThreeMobile}`}
                    >
                      <p>
                        Make no mistake though, the history of engastration is
                        as much about spectacle as any of Barnum's curios. It is
                        said that the cockentrice was first prepared for the
                        delight of King Henry VII, a roast that saw the front
                        half of a suckling pig stitched to the bottom half of a
                        capon, brushed with egg and saffron and gold leaf. The
                        Tudors ate Christmas Pie—turkey stuffed with goose
                        stuffed with chicken stuffed with partridge stuffed with
                        pigeon. Not to be outdone by the English, the
                        19th-century French rôti sans pareil—roast without
                        equal—contained 17 birds nested within one another. In
                        India, the Mughlai dish parinde-me-parinda packed an egg
                        into a quail into a chicken into a duck. In Greenland
                        some Inuit people celebrate weddings with kiviak,
                        hundreds of tiny auk (a type of sea bird) fermented
                        inside the belly of a seal. Closer to home we have the
                        turducken, our very own frankenfeat, and its sweeter
                        cousins, the piecakens and cherpumples.
                      </p>
                      <CircleVideoMorph />
                    </div>

                    <p>
                      What is happening here? This decadence, this pageantry,
                      this bending of nature to the will and whimsy of man? It's
                      hard not to see it as a display of power; a remixing of
                      the natural world, an intervention so outrageous as to
                      take on a kind of icky divinity. Cooking is itself an act
                      of creation; cooking like this, an exercise of dominion.
                    </p>
                    <p>
                      But we're not going to interrogate what is and is not
                      natural here. Because I'm gay and married and Jewish and
                      parent to a child created by way of donor sperm and IVF.
                      And there are nefarious forces who would like to call this
                      a cockentrice of a kind; or in the words of statutes in
                      NINE AMERICAN STATES, a “crime against nature.” Much
                      better, I think, to play it as it lays. Not a triumph of
                      man over nature so much as of wonder over practicality.
                      Not a conquest of a wild and fertile earth, but a
                      processing of its marvels, a reflection of its mysteries.{" "}
                    </p>
                    <p>
                      That first COVID winter was long and isolated, and we
                      still think now of how strange it was that Blake grew a
                      whole baby in that time and that so few people saw it
                      happen. The baby we called “X” then, suspended within
                      Blake, who was bouncing on a yoga ball and eating mostly
                      grapefruits, and me wrapping my entire body around the
                      both of them. Around us was the house, the snow drifts
                      waist high against the glass doors.
                    </p>

                    <div
                      className={`${styles.circleParagraph} ${styles.circleParagraphFour}`}
                    >
                      <div className={styles.left}>
                        <p>
                          The asparagus and sugar snaps were late that year, but
                          there was so much cabbage. In the kitchen I peeled
                          back a head of savoy, scooped out the heart and
                          replaced it with a ball of ground pork and fennel and
                          bright-yolked eggs, dry wine and old bread. I packed
                          the farce into each green leaf, rolling the petals
                          back into place one by one, like returning a bloomed
                        </p>
                      </div>
                      <div className={styles.right}>
                        <p>
                          rose to bud. It stood high in its pan, a mushroom
                          cloud, glossy with warm butter. In Gascony they call
                          this poulet verte, because it resembles a whole
                          chicken when bound with twine and lowered into a pot
                          to poach. I roasted it instead, until the filling set
                          and the cabbage collapsed into bolts of silk, and we
                          could eat it in tall wedges from bowls balanced on our
                          laps.
                        </p>
                      </div>
                      <CircleVideoMorph />
                    </div>
                    <div
                      className={`${styles.circleParagraphFourMobile} ${styles.circleParagraphMobile}`}
                    >
                      <p>
                        The asparagus and sugar snaps were late that year, but
                        there was so much cabbage. In the kitchen I peeled back
                        a head of savoy, scooped out the heart and replaced it
                        with a ball of ground pork and fennel and bright-yolked
                        eggs, dry wine and old bread. I packed the farce into
                        each green leaf, rolling the petals back into place one
                        by one, like returning a bloomed rose to bud. It stood
                        high in its pan, a mushroom cloud, glossy with warm
                        butter. In Gascony they call this poulet verte, because
                        it resembles a whole chicken when bound with twine and
                        lowered into a pot to poach. I roasted it instead, until
                        the filling set and the cabbage collapsed into bolts of
                        silk, and we could eat it in tall wedges from bowls
                        balanced on our laps.
                      </p>
                      <CircleVideoMorph />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-12-tablet is-11-desktop">
                  <h2 className={styles.h2}>
                    T<b>h</b>en <b>x</b> <b>b</b>e<b>g</b>a<b>n</b> t<em>o</em>{" "}
                    k<b>n</b>o<b>c</b>k.
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-9-desktop">
                  <div className="user-content-athena">
                    <p>
                      I was too eager, or X was too mischievous, or maybe both
                      of us were too shy, and I was always just missing her
                      kicks. Blake would take my hand and hold it in place and
                      we'd both be very still and stare at one another and she'd
                      say “aw, they were just doing it!” Until one night in bed,
                      when Blake, despite the swell of her belly, made a play
                      for big spoon and I sensed the rumble against my back. All
                      of us felt it. There were fingers and toes now, detailed
                      and conceivable. We made small happy sounds, our baby
                      between us, wanting to be known.
                    </p>
                    <div
                      className={`${styles.circleParagraph} ${styles.circleParagraphFive}`}
                    >
                      <div className={styles.left}>
                        <p>
                          My cooking changed—became less internal, more
                          conspicuous in its joy. When the rhubarb came I used
                          it to assemble an elaborate pattern in the bottom of a
                          cake pan. Flipped onto a wire rack, the finished cake
                          was beautiful but also guileless, just tart and sweet
                          and lovely. I tried something similar with onions,
                          fanning out their savory petals, dotting them with
                          butter and letting their caramel melt into pastry
                          dough. We ate it with gruyere, twisting a knife into
                          the wedge to break off salty hunks.
                        </p>
                      </div>
                      <div className={styles.right}>
                        <p>
                          I made so many tians: razor-thin coins of zucchini in
                          meticulous spirals, layers separated by breadcrumbs,
                          and baked until the squash is just starting to ripple.
                          And as X was beginning their final descent, I began
                          filling a chest freezer for the long days coming—rich
                          broths, braised meats, vats of tomato sauce that had
                          spit and hissed on the stove, cranberry beans in their
                          thick liquor, gingery congee, matzo balls, hulled
                          berries, herbs blitzed with oil and frozen in ice cube
                          trays.
                        </p>
                      </div>
                      <CircleVideoMorph />
                    </div>
                    <div
                      className={`${styles.circleParagraphMobile} ${styles.circleParagraphFiveMobile}`}
                    >
                      <p>
                        My cooking changed—became less internal, more
                        conspicuous in its joy. When the rhubarb came I used it
                        to assemble an elaborate pattern in the bottom of a cake
                        pan. Flipped onto a wire rack, the finished cake was
                        beautiful but also guileless, just tart and sweet and
                        lovely. I tried something similar with onions, fanning
                        out their savory petals, dotting them with butter and
                        letting their caramel melt into pastry dough. We ate it
                        with gruyere, twisting a knife into the wedge to break
                        off salty hunks. I made so many tians: razor-thin coins
                        of zucchini in meticulous spirals, layers separated by
                        breadcrumbs, and baked until the squash is just starting
                        to ripple. And as X was beginning their final descent, I
                        began filling a chest freezer for the long days
                        coming—rich broths, braised meats, vats of tomato sauce
                        that had spit and hissed on the stove, cranberry beans
                        in their thick liquor, gingery congee, matzo balls,
                        hulled berries, herbs blitzed with oil and frozen in ice
                        cube trays.
                      </p>
                      <CircleVideoMorph />
                    </div>
                  </div>
                </div>
              </div>

              <div className="columns is-tablet is-centered">
                <div className="column is-12-tablet is-11-desktop">
                  <h2 className={styles.h2}>
                    W<b>e</b> <b>W</b>r<em>o</em>
                    <b>t</b>e <b>A</b> B<b>i</b>r<b>t</b>h P<b>l</b>a<b>n.</b> W
                    <b>e</b> P<b>l</b>a<b>y</b>ed <b>G</b>i<b>n</b> <b>R</b>u
                    <b>m</b>m<b>y.</b> W<b>e</b> <b>W</b>ai<b>t</b>ed.
                  </h2>
                </div>
              </div>
              <div className="columns is-tablet is-centered">
                <div className="column is-10-tablet is-9-desktop">
                  <div className="user-content-athena">
                    <p>
                      When she arrived we named her Roux, for the mother sauce.
                      For fat and flour. For things elemental. Her first food
                      was breast milk. Then Japanese sweet potatoes simmered in
                      broth. Peas crushed with grassy olive oil. There would be
                      time enough for complicated things.
                    </p>
                    <p>
                      That coulibiac, for example. Your friends will hold their
                      breath as you slice through the lace-wrapped pastry,
                      expose its layers of salmon and rice, dill and egg and
                      very hot mustard. You'll tell them that the tsars once ate
                      theirs with vesiga, the spinal marrow of a sturgeon, but
                      that sort of thing is not readily available in upstate New
                      York. These friends are newly pregnant and so you cook
                      them this special dish, and you tell them it is in a
                      category of dishes you call “things-in-things,” with which
                      you became very familiar when your own baby was finding
                      her way home.{" "}
                    </p>
                    <p>
                      Roux will be at the table too. She will like the buttery
                      crust best, and she will send flakes of pastry flying from
                      her fingers as she signs “more! more! more!” She
                      understands everything you say these days; she doesn't
                      speak much yet.{" "}
                    </p>
                    <p>
                      Inside of her, things are happening. When they are ready,
                      she will know.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Contributors
              zeus={article.zeusContributors}
              // other={article.otherContributors}
              customContrib="Jordana"
            />
            <div className={styles.moreFrom}>
              <MoreArticles id={article.id} issue={article.issue[0].id} />
            </div>
            <div className={styles.preFooterImg}>
              <img src={footerImg} alt="Pre Footer" />
            </div>
          </div>
        </div>
        <PreFooter style={{ marginTop: 0 }} />
      </article>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query($slug: String!) {
    craft {
      issues: categories(group: "athenaIssues", orderBy: "dateCreated asc") {
        id
      }
      article: entry(slug: [$slug], section: "athenaArticles") {
        id
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          coverImages {
            ... on Craft_coverImages_BlockType {
              ratio_16x9 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
              ratio_4x3 {
                id
                url
                width
                height
                ... on Craft_zjContent_Asset {
                  altText
                }
              }
            }
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
    }
  }
`
